import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HclConfigService } from '../../config/hcl.config.service';
import { CONTENT_TYPE } from '../../constants/content-type';
import { HttpShowError } from '../../decorator/http/http-show-error.decorator';
import { InvocationResult } from '../../interfaces/invocation-result';
import { User } from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) { }

  @HttpShowError()
  getUserWithNestedResources(id: string, cid?: string): Observable<User> {
    let headers: HttpHeaders = new HttpHeaders().append(
      'Accept',
      CONTENT_TYPE.INCLUDE_NESTED_OBJECTS
    )
    let queryParams = new HttpParams()
    if (cid) {
      queryParams = queryParams.set('cid', cid)
    }
    return this.httpClient.get(`${this.configService.cmnUserApiBaseUrl}/users/${id}`, { params: queryParams, headers }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
