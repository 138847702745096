import { Injectable } from '@angular/core';
import { createAction, props, Store } from "@ngrx/store";
import { HumecloudState } from '.';

@Injectable({
  providedIn: 'root'
})
export class ScopeActions {
  static SCOPE_UPDATE = '[Scope] Update';

  constructor(
    private store: Store<HumecloudState>
  ) {
    // do nothing
  }

  updateScope(scope: any): void {
    this.store.dispatch(updateScope({
      scope: scope
    }));
  }
}

export const updateScope = createAction(
  ScopeActions.SCOPE_UPDATE,
  props<{ scope: string; }>()
);
