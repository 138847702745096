import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpShowError } from 'projects/hcl-lib/src/lib/decorator/http/http-show-error.decorator'
import { InvocationResult } from 'projects/hcl-lib/src/lib/interfaces/invocation-result'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HclConfigService } from '../../config/hcl.config.service'
import { App } from '../../interfaces/app'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) {
    // do nothing
  }

  @HttpShowError()
  getApp(id: string): Observable<App> {
    return this.httpClient.get<InvocationResult>(this.configService.cmnAppApiBaseUrl + '/apps/' + id).pipe(
      map(res => res.data)
    )
  }
}
