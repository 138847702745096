import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpShowError } from 'projects/hcl-lib/src/lib/decorator/http/http-show-error.decorator'
import { InvocationResult } from 'projects/hcl-lib/src/lib/interfaces/invocation-result'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HclConfigService } from '../../config/hcl.config.service'
import { AppPlugin } from '../../interfaces/app-plugin'

@Injectable({
  providedIn: 'root'
})
export class PluginService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) {
    // do nothing
  }

  @HttpShowError()
  getPlugin(id: string): Observable<AppPlugin> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/plugins/' + id).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getPluginsOfApp(appId: string): Observable<AppPlugin[]> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/plugins/?appId=' + appId).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getPlugins(): Observable<AppPlugin[]> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/plugins').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
