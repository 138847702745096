import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { License } from "../../interfaces/license";
import { LicenseDto } from "../../interfaces/dto/license-dto";
import { HclConfigService } from "../../config/hcl.config.service";
import { HttpShowError } from "../../decorator/http/http-show-error.decorator";
import { InvocationResult } from "../../interfaces/invocation-result";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) { }

  @HttpShowError()
  getLicensesWithPaging(page: Number, perPage: Number): Observable<InvocationResult> {
    const url = this.configService.cmnAppApiBaseUrl + '/licenses'
    const queryParams = new HttpParams()
      .set('childCustomers', 'false')
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getCurrentLicense(customerId: string, appName: string): Observable<License> {
    const url = this.configService.cmnAppApiBaseUrl + '/licenses/current'
    const queryParams = new HttpParams()
      .set('customerId', customerId)
      .set('appName', appName)
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  subscribeTrialLicense(appId: string): Observable<License> {
    const url = this.configService.cmnAppApiBaseUrl + '/licenses/' + appId + '/trial'
    return this.httpClient.post(url, null).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getLicenses(): Observable<License[]> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/licenses').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  createLicense(license: License): Observable<License> {
    const licenseDto: LicenseDto = {
      customerId: license.customerId,
      licenseTypeId: license.licenseTypeId,
      sellingCompanyId: license.sellingCompanyId,
      sellerId: license.sellerId,
      start: license.start,
      autoRenewal: license.autoRenewal,
      enabled: license.enabled,
      pluginIds: license.pluginIds
    }
    return this.httpClient.post(this.configService.cmnAppApiBaseUrl + '/licenses', licenseDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getLicense(id: string): Observable<License> {
    return this.httpClient.get(this.configService.cmnAppApiBaseUrl + '/licenses/' + id).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  updateLicense(license: License): Observable<License> {
    const licenseDto: LicenseDto = {
      appId: license.appId,
      customerId: license.customerId,
      licenseTypeId: license.licenseTypeId,
      sellingCompanyId: license.sellingCompanyId,
      sellerId: license.sellerId,
      start: license.start,
      end: license.end,
      quota: license.quota,
      autoRenewal: license.autoRenewal,
      enabled: license.enabled,
      pluginIds: license.pluginIds
    }
    return this.httpClient.put(this.configService.cmnAppApiBaseUrl + '/licenses/' + license.id, licenseDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  deleteLicense(id: string) {
    return this.httpClient.delete(this.configService.cmnAppApiBaseUrl + '/licenses/' + id)
  }

  @HttpShowError(true)
  upgradeLicense(licenseId: string, licenseTypeId: string, licenseStart: Date): Observable<License> {
    const queryParams = new HttpParams()
      .set('licenseTypeId', licenseTypeId)
      .set('start', licenseStart.toISOString())
    return this.httpClient.post(this.configService.cmnAppApiBaseUrl + '/licenses/' + licenseId + '/upgrade', null, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
